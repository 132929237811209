// Services.js
import React, { useState } from 'react';
import './Services.css';

function Services() {
  const [activeTab, setActiveTab] = useState('All');

  const services = {
    'CSS': [
      { title: 'Converting to mobile view compatible website (Simple website - 6 pages)', duration: '8 Hours' },
      { title: 'Applying theme (Simple website with 6 pages)', duration: '24 Hours' },
    ],
    'Authentication': [
      { title: 'Gmail/ Twitter/ Facebook  Authentication (each auhentication)', duration: '8 Hours' },
      { title: 'Only Contact Form with custome emailid configuration', duration: '8 Hours' },
      { title: 'Only Contact Form with custome emailid configuration (open-source)', duration: '16 Hours' },
      
      /* { title: 'Twitter Authentication', duration: '5 Hours' },
      { title: 'Facebook Authentication', duration: '5 Hours' }, */
      
      { title: 'OTP Authentication', duration: '16 Hours' },
      { title: 'New  Admin Panel, dashboard using cloud database', duration: '32 Hours' },
      
      
    ],'Open Source Database': [
      { title: 'Data Migration to cloud Database', duration: '50 Hours' },
      { title: 'Creating CRUD Operations on cloud database (Single table)', duration: '24 Hours' },
      
    ],'Export': [
      { title: 'Only PDF & Excel Export', duration: '8 Hours' },
      { title: 'File Upload & List upload', duration: '8 Hours' },
      
    ],
    'GIS Mapping': [
      { title: 'Coordinate Mapping (GIS)', duration: '24 hours' },
      { title: 'Advance Mapping (GIS)', duration: '40 hours' },
    ],
    'Video Conference': [
      { title: 'API Integration (Twilio, Videotalksdk)', duration: '32 hours' },
      { title: 'Complete Conference System', duration: '176 Hours' },
    ],
    'AI Chatbot': [
      { title: 'Rule Based', duration: '40 hours' },
      { title: 'Advanced Customization', duration: '120 hours' },
    ],
    
  };

  // Generate table rows
  const renderTableRows = () => {
    let allServices = [];
    
    if (activeTab === 'All') {
      allServices = Object.values(services).flat();
    } else {
      allServices = services[activeTab];
    }

    return allServices.map((item, index) => (
      <tr key={index}>
        <td className='sr-no'>{index + 1}</td>
        <td className='service-title'>{item.title}</td>
        <td className='duration'>{item.duration}</td>
      </tr>
    ));
  };

  return (
    <div className="about">
      <div className="outer-about">
        {/* Tabs for selecting services */}
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === 'All' ? 'active' : ''}`}
            onClick={() => setActiveTab('All')}
          >
            All
          </button>
          {Object.keys(services).map((service) => (
            <button
              key={service}
              className={`tab-button ${activeTab === service ? 'active' : ''}`}
              onClick={() => setActiveTab(service)}
            >
              {service}
            </button>
          ))}
        </div>

        {/* Single Table for All Categories */}
        <div className='note'><span><b>Rate:</b> 5$ / Hour</span></div>
        <div className='note'><span><b><u>Declaration:</u></b> In case development time exceeds the mentioned hours in table, rates will be charged only for duration as mention in table. </span> 
        </div>
        <div className="table-container">
        
           <table className="responsive-table">
            <thead>
              <tr>
                <th className="sr-no">Sr. No.</th>
                <th className="service-title">Service Name</th>
                <th className="duration">Duration</th>
              </tr>
            </thead>
            <tbody>
              {renderTableRows()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Services;
