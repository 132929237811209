import React, { useState } from 'react';
import './ProjectCard.css';

const ProjectCard = ({ project, animationDelay }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);

  // Function to truncate description
  const truncateToWords = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };

  return (
    <>
      <div
        className="project-card animated"
        style={{ animationDelay: `${animationDelay}s` }}
      >
        {/* Project Title */}
        <h3>{project.title}</h3>

        {/* Project Description */}
        <p>
          {truncateToWords(project.description, 20)}
          {project.description.split(' ').length > 20 && (
            <span className="more-link" onClick={openModal}>
              {' _more_'}
            </span>
          )}
        </p>

        {/* Project Tools */}
        <div className="project-tools">
          <strong>Tools: </strong>
          {project.tools && project.tools.length > 0 ? (
            <ul>
              {project.tools.map((tool, index) => (
                <li key={index}>{tool}</li>
              ))}
            </ul>
          ) : (
            <span>No tools specified</span>
          )}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{project.title}</h2>
            <p>{project.description}</p>

            {project.tools && project.tools.length > 0 && (
              <div className="tools">
                <strong>Tools Used:</strong>
                <ul>
                  {project.tools.map((tool, index) => (
                    <li key={index}>{tool}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectCard;
