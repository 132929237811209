import React, { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import './ProjectGallery.css';

const categories = ['All', 'ReactJs', 'PHP'];

const ProjectGallery = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const [projectData, setProjectData] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    // Fetch project data from JSON file
    fetch('/projects.json')
      .then((response) => response.json())
      .then((data) => {
        console.log("Loaded projects:", data.length); // Debugging
        setProjectData(data);
        setFilteredProjects(data);
      })
      .catch((error) => console.error('Error loading project data:', error));
  }, []);

  useEffect(() => {
    const filtered =
      activeCategory === 'All'
        ? projectData
        : projectData.filter((project) => project.category === activeCategory);

    const timeout = setTimeout(() => {
      setFilteredProjects(filtered);
    }, 300); // Matches animation duration

    return () => clearTimeout(timeout);
  }, [activeCategory, projectData]);

  return (
    <div className="gallery-container">
      <div className="category-buttons">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={activeCategory === category ? 'active' : ''}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="project-grid">
        {filteredProjects.map((project, index) => (
          <ProjectCard
            key={project.id}
            project={project}
            animationDelay={index * 0.2} // Add delay for each card
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectGallery;
